import React, {useState} from 'react'
import styled from 'styled-components'
import {useStaticQuery, graphql} from 'gatsby'
import InfoIcon from '../../images/icons/icon-info-white.svg'
import FacebookSvg from '../../images/svg/facebook.jsx'
import TwitterSvg from '../../images/svg/twitter.jsx'
import TeamImagePlaceholder from '../../images/team-image-placeholder.jpg'

const large = '850px'
const medium = '600px'
const small = '400px'

const Grid = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (min-width: ${small}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${medium}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${large}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default function Team() {
  const {allWordpressWpTeamMember} = useStaticQuery(graphql`
    query {
      allWordpressWpTeamMember(filter: {status: {eq: "publish"}}) {
        edges {
          node {
            title
            excerpt
            content
            featured_media {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [100]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            acf {
              position
              social_links {
                title
                link
              }
            }
          }
        }
      }
    }
  `)

  const [activeIndex, setActiveIndex] = useState(9999)
  const enhancedSetActiveIndex = index => {
    const idx = index === activeIndex ? 99999 : index
    setActiveIndex(idx)
  }

  const {edges} = allWordpressWpTeamMember
  return (
    <Grid>
      {edges.map(({node}, i) => (
        <TeamItem
          key={node.title}
          isActive={activeIndex === i}
          index={i}
          title={node.title}
          position={node.acf.position}
          description={node.content}
          imageURL={
            node.featured_media
              ? node.featured_media.localFile.childImageSharp.fluid.src
              : TeamImagePlaceholder
          }
          handleClick={enhancedSetActiveIndex}
          socialArray={node.acf.social_links}
        />
      ))}
    </Grid>
  )
}

// ====================================================

const Container = styled.div`
  /* Medium Responsive */
  &:nth-child(odd) div.detail {
    @media (min-width: ${medium}) {
      margin-left: 0;
    }
  }
  &:nth-child(even) div.detail {
    @media (min-width: ${medium}) {
      margin-left: calc(-100% + -20px);
    }
  }
  /* Large Responsive */
  &:nth-child(3n + 1) div.detail {
    @media (min-width: ${large}) {
      margin-left: 0;
    }
  }
  &:nth-child(3n + 2) div.detail {
    @media (min-width: ${large}) {
      margin-left: calc(-100% + -20px);
    }
  }
  &:nth-child(3n + 3) div.detail {
    @media (min-width: ${large}) {
      margin-left: calc(-200% + -40px);
    }
  }
`

const Image = styled.div`
  min-height: 250px;
  background: ${({url}) => `url(${url})`};
  background-size: cover;
  background-position: center top;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  cursor: pointer;
  position: relative;
  @media (max-width: ${medium}) {
    & div {
      margin-bottom: 0 !important;
      opacity: 1 !important;
    }
  }
  ${({active, theme}) =>
    active
      ? `
    // ====================================
    //  pseudo element styling for active items
    // ====================================
    &:after {
        display:block;
        content: '';
        width: 0;
	    height: 0;
	    border-left: 50px solid transparent;  /* left arrow slant */
	    border-right: 50px solid transparent; /* right arrow slant */
	    border-bottom: 50px solid ${theme.grey2};
	    font-size: 0;
        line-height: 0;
        position: absolute;
        bottom: -20px;
    }
    &:before {
        display:block;
        content: '';
        width: 0;
	    height: 0;
	    border-left: 50px solid transparent;  /* left arrow slant */
	    border-right: 50px solid transparent; /* right arrow slant */
	    border-bottom: 50px solid white; /* bottom, add background color here */
	    font-size: 0;
        line-height: 0;
        position: absolute;
    }

    // ====================================
    //  Hide name on image for active item
    // ====================================

    & div {
        display: none;
    }
  `
      : `
    // ====================================
    //   Name reveal hover for non-active items
    // ====================================

    overflow: hidden;

    & div {
        margin-bottom: -50px;
        opacity: 0;
        transition: 0.2s all ease-in-out;
        display: flex;
        width: 100%;
        justify-content: center;
        height: 80px;
        align-items: center;
        font-size: 20px;
        color: white;
        background: linear-gradient(transparent, #0000009c);
    }

    &:hover div {
        margin-bottom: 0;
        opacity: 1;
    }
      `}
  h3 {
    color: white;
  }
  & img {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: white;
    background: #00000033;
    box-shadow: 0 0 5px 2px #0000002b;
    border-radius: 200px;
  }
`

const Detail = styled.div`
  display: ${({active}) => (active ? 'block' : 'none')};
  background: ${({theme}) => theme.grey2};
  margin-top: 20px;
  z-index: 3;
  padding: 40px;
  color: white;
  h3,
  h4,
  p {
    color: white;
  }
  h3 {
    margin-bottom: 0;
  }
  h4 {
    border: none;
    font-weight: 100;
    font-size: 12px;
    padding-bottom: 20px;
    margin-top: 5px;
    font-style: italic;
  }
  @media (min-width: ${small}) {
    grid-template-columns: 1fr;
  }
  @media (min-width: ${medium}) {
    width: calc(200% + 20px);
  }
  @media (min-width: ${large}) {
    width: calc(300% + 40px);
  }
`

const SocialLink = styled.a`
  padding: 10px;
  border-radius: 2000px;
  background: ${({theme}) => theme.grey1};
  width: 40px;
  height: 40px;
`

const TopDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const SocialFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    transition: 0.2s all ease-in-out;
    &.twitter:hover {
      background: #1da1f2;
    }
    &.facebook:hover {
      background: #4267b2;
    }
  }
`

function TeamItem({
  isActive,
  title,
  description,
  imageURL,
  handleClick,
  index,
  position,
  socialArray,
}) {
  return (
    <Container onClick={() => handleClick(index)} active={isActive}>
      <Image active={isActive} url={imageURL}>
        <img src={InfoIcon} alt="extra info" />
        <div>
          <h3>{title}</h3>
        </div>
      </Image>
      <Detail active={isActive} className="detail">
        <TopDetailRow>
          <div>
            <h3>{title}</h3>
            <h4>{position}</h4>
          </div>
          <SocialFlexContainer>
            {socialArray.map((el, i) => (
              <SocialLink href={el.link} className={el.title} key={i}>
                {el.title === 'twitter' ? <TwitterSvg /> : <FacebookSvg />}
              </SocialLink>
            ))}
          </SocialFlexContainer>
        </TopDetailRow>
        <p dangerouslySetInnerHTML={{__html: description}} />
      </Detail>
    </Container>
  )
}
