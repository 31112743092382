import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import PageHeader from '../../components/Layout/PageHeader'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Team from '../../components/Team'
import {Inner} from '../../components/styles'

const OurTeamPage = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      wp: wordpressPage(wordpress_id: {eq: 125}) {
        title
        content
      }
      seo: wpgraphql {
        pageBy(pageId: 125) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)
  return (
    <Layout location={location}>
      <SEO location={location} seo={data.seo.pageBy.seo} title="Our Team" />
      <PageHeader location={location}>{data.wp.title}</PageHeader>
      <Inner>
        <div
          className="content"
          dangerouslySetInnerHTML={{__html: data.wp.content}}
        />
        <Team />
      </Inner>
    </Layout>
  )
}

export default OurTeamPage
